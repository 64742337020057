import { Injectable } from '@angular/core';
import { RequestService } from '@app/+shared/_services/request.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private requestService: RequestService) {}
  /**
   * Get list of categories related with games from database
   *
   * @method getAllCategories
   * @param none
   */
  getAllCategories(): Observable<any> {
    return this.requestService.get('GET_ALL_GAME_CATEGORIES');
  }
}
