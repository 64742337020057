<footer>
  <div class="logo">
    <a [routerLink]="'/'" title="Home" class="logo-link">
      <img src="{{ env.imagePath }}logo-footer.png" alt="logo">
    </a>
  </div>
  <ul class="footer-links">
    <li><a [routerLink]="'about'" title="About">About</a></li>
    <li><a [routerLink]="'privacy-policy'" title="Privacy Policy">Privacy Policy</a> </li>
    <li><a [routerLink]="'contact'" title="Contact">Contact</a> </li>
  </ul>
</footer>
