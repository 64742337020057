export const dateFormat: string = 'DD/MM/YYYY';
export const dateFormatUS: string = 'MM/DD/YYYY';
export const dateFormatUSDashed: string = 'MM-DD-YYYY';
export const DBDateFormat: string = 'YYYY-MM-DD';
export const dateAndTimeDashed: string = 'MM-DD-YYYY HH:mm:ss A';
export const releaseDateFormat: string = 'Do MMMM, YYYY';
export const dayInMonth: string = 'Do MMMM'; // 18th January
export const urlDateFormat: string = 'MMMM YYYY';
export const urlDateFormatShort: string = 'MM YYYY';

