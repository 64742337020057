import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { searchResultsUrl } from '@app/+admin/_constants/urls';
import { SearchService } from '@app/+core/_services/search.service';
import { ICategory } from '@app/+shared/_models';
import { LocalStorageService } from '@app/+shared/_services/local-storage.service';
import { NotificationService } from '@app/+shared/_services/notification.service';
import { CATEGORY_ID_KEY } from '@app/+shared/_utils/constants';
import { environment } from '@env';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss']
})
export class CategoryFilterComponent implements OnInit {
  redirectUrl = searchResultsUrl;
  env: any = environment;
  @Input() categoriesList: ICategory[];
  @Output() hideCategoryDropdown: EventEmitter<boolean> = new EventEmitter<boolean>()

  form: FormGroup;
  selectedCategoryLabels:string[] = [];
  categoryData = [];
  themeType = 'theme-dark';
  get genresFormArray() {
    return this.form.controls.genres as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private searchService: SearchService,
    private notificationService: NotificationService
    ) {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    this.categoryData = [...this.categoriesList]
    this.form = this.formBuilder.group({
      genres: new FormArray([])
    });
    this.getCurrentTheme();
    this.addCheckboxesToForm();
  }
  /**
   * Set current theme based on user selection
   *
   * @method getCurrentTheme
   * @param none
   */
  getCurrentTheme(): void {
    this.notificationService.currentThemeSubject$.subscribe((name:string) => {
      if (name) {
        this.themeType = name;
      }
    })
  }
  private addCheckboxesToForm(): void {
    const selectedCategories = localStorage.getItem(CATEGORY_ID_KEY);
    const categoriesIDs = eval(JSON.parse(selectedCategories));

    this.categoryData.forEach((type) => {
      const selected = categoriesIDs && categoriesIDs.length > 0 ? categoriesIDs.some(val => val === type.id) : false;
      this.genresFormArray.push(new FormControl(selected))
    });

  }
  /**
  *  Reset/uncheck all selected categories
  *
  * @method unCheckAll
  * @param none
  */
  unCheckAll(): void {
    this.genresFormArray.controls.map(x => x.patchValue(false))
    this.localStorageService.delete(CATEGORY_ID_KEY);
  }

  closeDropdown(val: boolean): void {
    if(!val){
      return;
    }
    this.hideCategoryDropdown.emit(true)
  }

  submit(): void {
    const value = this.form.value.genres
      .map((checked, i) => checked ? this.categoryData[i].id : null)
      .filter(v => v !== null);
    this.handleSelectedCategories(value)
  }

  handleSelectedCategories(categoriesIDs:number[]): void {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    const selectedCategoryIDs = [];
    const selectedCategoryNames = [];
    this.categoryData.forEach((c: any) => {
      categoriesIDs.forEach((o1: number) => {
          if (o1 === +c.id) {
            selectedCategoryNames.push(c.categoryLabel);
            selectedCategoryIDs.push(c.id)
          }
      });
    });
    this.searchService.setCategoryIds(selectedCategoryIDs);

    // Save selected category IDs in local storage
    this.localStorageService.set(CATEGORY_ID_KEY, JSON.stringify(selectedCategoryIDs));
    // Send selected category IDs to server
    this.searchService.searchByCategory(this.activatedRoute, selectedCategoryIDs);
  }
}
