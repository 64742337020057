import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { SearchService } from '@app/+core/_services/search.service';
import { ISearchResult } from '@app/+shared/_models';
import { debounceTime } from 'rxjs/operators';
import { environment } from '@env';
import { releaseDateFormat } from '@app/+shared/_constants/date-format';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {
  env: any = environment;
  subscriptions: Subscription[] = [];
  @ViewChild('searchText', { static: false }) searchBox: ElementRef;
  @Output() onSearchTextKeyup = new EventEmitter<string>();
  @Output() onSearchTextEnter = new EventEmitter<string>();
  searchText = '';
  searchResponseList: {
    name: string,
    link: string,
    releaseDate: string;
    isBefore: boolean
  }[] | null = null;
  isSearchIcon = true;
  showAutocomplete = false;
  private searchTerms: Subject<string> = new Subject();
  constructor(private searchService: SearchService, public cd: ChangeDetectorRef) {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    this.searchForResults();

    this.subscriptions.push(
      this.searchService.searchResponse$.subscribe(
        (response: ISearchResult[]) => {
          if (response) {
            this.searchResponseList = response.map((item: ISearchResult) => {
                return {
                  name: item.name,
                  link: item.link,
                  releaseDate: moment(new Date(item.releaseDate)).format(
                    releaseDateFormat
                  ),
                  isBefore: this.compareDates(item.releaseDate)
                };
              });
          } else {
            this.searchResponseList = [];
          }
        }
      )
    );
  }
  /**
   * Get text from input on keyUp event
   *
   * @method searchGameByText
   * @param {String} term
   */
  searchGameByText(term: string): void {
    this.searchText = term;
    if (term.length > 0) {
      this.isSearchIcon = false;
      this.showAutocomplete = true;
    } else if (term.length === 0) {
      this.isSearchIcon = true;
      this.showAutocomplete = false;
    }
    this.cd.detectChanges();
    this.searchTerms.next(term);
    if (term.length === 0 && this.searchBox) {
      this.restoreSearchData();
    }
  }
  /**
   * Reset search input values on click
   *
   * @method resetSearch
   * @param none
   */
  resetSearch(): void {
    this.searchText = '';
    this.restoreSearchData();
  }
  /**
   * Hide element if there was a click outside of it.
   *
   * @method hideDropDown
   * @param event
   */
  hideAutocompleteDropDown(event: PointerEvent): void {
    if (!event) {
      return;
    }
    this.showAutocomplete = false;
  }
  /**
   * Restore search realated properties and
   * hide autocomplete dropdown
   *
   * @method restoreSearchData
   * @param none
   */
  restoreSearchData(): void {
    this.searchBox.nativeElement.value = '';
    this.isSearchIcon = true;
    this.showAutocomplete = false;
    this.searchResponseList = [];
  }
  /**
   * Send/receive search text to service
   *
   * @remarks this method use debounceTime() rxjs operator
   *
   * @method searchForResults
   * @param none
   */
  searchForResults(): void {
    this.subscriptions.push(
      this.searchTerms
        .pipe(debounceTime(500))
        .subscribe((searchTextValue: string) => {
          this.onSearchTextKeyup.emit(searchTextValue);
        })
    );
  }
  /**
   * Serach event by pressing enter without submit button
   *
   * @method searchOnEnter
   * @param {Object} event
   */
  searchOnEnter(event: KeyboardEvent): void {
    if (event.keyCode === 13 || event.key === 'Enter') {
      event.preventDefault();
      const target = event.target as HTMLTextAreaElement;
      this.onSearchTextEnter.emit(target.value);
    }
  }
  /**
   * Show more search results on click
   *
   * @method showMoreByKeyowrd
   * @param {String} keyword
   */
  showMoreByKeyowrd(keyword: string): void {
    if (!keyword) {
      return;
    }
    this.onSearchTextEnter.emit(keyword);
    this.showAutocomplete = false;
  }
  /**
   * Compare dates to check if release date is in the past
   *
   * @method compareDates
   * @param {String} releaseDate
   */
  compareDates(releaseDate: string): boolean {
    return moment(releaseDate).isBefore();
  }
  /**
   * Cleanup just before Angular destroys the component
   *
   * @remarks Unsubscribe Observables from list and detach event handlers to avoid memory leaks
   *
   * @method ngOnDestroy
   * @param none
   */
  ngOnDestroy(): void {
    /**
     * Removes Observables from list
     */
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
