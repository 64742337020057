import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  env: any = environment;
  constructor() {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {}
}
