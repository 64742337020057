import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ICategory } from '@app/+shared/_models';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  @Input() categoriesList: ICategory[];
  @Input() selectedItems: ICategory[];
  @Output() onSelectCategory = new EventEmitter<ICategory[]>();
  @Output() hideDropdown = new EventEmitter<Boolean>();
  checkboxesDataList: ICategory[] = [];
  constructor() {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    this.fetchSelectedItems();
  }
  /**
   * Update checkbox list with selected items
   *
   * @method fetchSelectedItems
   * @param none
   */
  fetchSelectedItems(): void {
    this.checkboxesDataList = [];
    this.categoriesList.forEach((o1: ICategory) => {
      this.selectedItems.forEach((o2: ICategory) => {
        if (o1.id === o2.id) {
          o1.isChecked = 1;
        }
      });
    });
    this.checkboxesDataList = [...this.categoriesList];
  }
  /**
   * Filter checked categories from list
   *
   * @method filterSelectedCategories
   * @param none
   */
  filterSelectedCategories(): ICategory[] {
    return this.checkboxesDataList.filter((value) => value.isChecked);
  }
  /**
   * Update checkbox state based on change event and received id value
   *
   * @method changeSelection
   * @param {Number} id
   */
  changeSelection(): void {
    this.filterSelectedCategories();
  }
  /**
   * Uncheck all selected checkboxes
   *
   * @method resetAll
   * @param none
   */
  resetAll(): void {
    this.checkboxesDataList.forEach((item) => item.isChecked = 0);
  }
  /**
   * Collect checkboxes values on click
   *
   * @remarks Get categories data
   *
   * @method addCategory
   * @param none
   */
  addCategory(): void {
    const selectedCategories: ICategory[] = this.filterSelectedCategories();
    this.onSelectCategory.emit(selectedCategories);
    this.hideDropdown.emit(true);
  }
}
