<section class="helper-page">

  <h1 class="helper-page-title">Don't hesitate to contact with us</h1>

  <form role="form" [formGroup]="contactForm" id="contactForm" (ngSubmit)="onSubmit()">
    <fieldset>

      <div class="relative mb-24">
        <input
        type="text"
        id="full-name"
        class="gtw-input"
        formControlName="fullName"
        placeholder="Full name">

      <span class="error absolute contact-form"
        *ngIf="contactForm.get('fullName').hasError('required') && contactForm.get('fullName').touched">
        Name required
      </span>

      <span class="error absolute contact-form" *ngIf="
          !contactForm.get('fullName').hasError('required') &&
          contactForm.get('fullName').touched &&
          !contactForm.get('fullName').hasError('maxlength') &&
          contactForm.get('fullName').hasError('minlength')
        ">
        Minimum 2 characters required
      </span>

      <span class="error absolute contact-form" *ngIf="
        !contactForm.get('fullName').hasError('required') &&
        contactForm.get('fullName').touched &&
        !contactForm.get('fullName').hasError('minlength') &&
        contactForm.get('fullName').hasError('maxlength')
      ">
        Maximum 30 characters required
      </span>

      <span class="error absolute contact-form" *ngIf="
        !contactForm.get('fullName').hasError('required') &&
        contactForm.get('fullName').touched &&
        !contactForm.get('fullName').hasError('minlength') &&
        !contactForm.get('fullName').hasError('maxlength') &&
        contactForm.get('fullName').hasError('pattern')
      ">
        Only letters allowed
      </span>
      </div>

    <div class="relative mb-24">
      <input
      type="text"
      id="contact-email"
      class="gtw-input"
      formControlName="email"
      placeholder="Email">
      <span class="error absolute contact-form"
        *ngIf="contactForm.get('email').hasError('required') && contactForm.get('email').touched">
        Email required
      </span>

      <span class="error absolute contact-form" *ngIf="
          !contactForm.get('email').hasError('required') &&
          contactForm.get('email').touched &&
          contactForm.get('email').hasError('pattern')
        ">
        Invalid email address
      </span>
    </div>


    <div class="relative mb-24">
      <input
      type="text"
      id="contact-subject"
      class="gtw-input"
      formControlName="subject"
      placeholder="Subject">

      <span class="error absolute contact-form"
        *ngIf="contactForm.get('subject').hasError('required') && contactForm.get('subject').touched">
        Subject required
      </span>

      <span class="error absolute contact-form" *ngIf="
          !contactForm.get('subject').hasError('required') &&
          contactForm.get('subject').touched &&
          !contactForm.get('subject').hasError('maxlength') &&
          contactForm.get('subject').hasError('minlength')
        ">
        Minimum 2 characters required
      </span>

      <span class="error absolute contact-form" *ngIf="
        !contactForm.get('subject').hasError('required') &&
        contactForm.get('subject').touched &&
        !contactForm.get('subject').hasError('minlength') &&
        contactForm.get('subject').hasError('maxlength')
      ">
        Maximum 30 characters required
      </span>

    </div>

    <div class="relative mb-24">
    <textarea
      name="message"
      id="contact-message"
      class="gtw-textarea"
      formControlName="message"
      placeholder="Message"></textarea>

      <span class="error absolute contact-form-message"
        *ngIf="contactForm.get('message').hasError('required') &&
        contactForm.get('message').touched">
        Message required
      </span>

      <span class="error absolute contact-form-message" *ngIf="
          !contactForm.get('message').hasError('required') &&
          contactForm.get('message').touched &&
          contactForm.get('message').hasError('minlength')
      ">
        Minimum 5 characters allowed
      </span>

      <span class="error absolute contact-form-message" *ngIf="
          !contactForm.get('message').hasError('required') &&
          contactForm.get('message').touched &&
          contactForm.get('message').hasError('maxlength')
      ">
        Maximum 500 characters allowed
      </span>

    </div>
    <div class="d-flex justify-content-end">
      <button type="submit" class="gtw-btn">Submit</button>
    </div>

    </fieldset>
  </form>
</section>
