<app-header></app-header>

<div class="wrapper">
  <div class="content" role="main">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="spinner-container" *appShellRender>
  <span class="spinner"></span>
</div>

<!--.wrapper-->
<app-footer></app-footer>
