import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-sidebar-nav',
  templateUrl: './mobile-sidebar-nav.component.html',
  styleUrls: ['./mobile-sidebar-nav.component.scss']
})
export class MobileSidebarNavComponent {
  @Output() hideMobileSidebar: EventEmitter<void> = new EventEmitter<void>()
  constructor(private router: Router) {}
  navigation = [{
      name: 'Games TBA',
      link: '/tba/games',
      title: 'Games To Be Annouced'
    },
    {
      name: 'About',
      link: 'about',
      title: 'About'
    },
    {
      name: 'Privacy Policy',
      link: 'privacy-policy',
      title: 'Privacy Policy'
    },
    {
      name: 'Contact',
      link: 'contact',
      title: 'Contact'
    }
  ];

  goToPage(link: string):void {
    if (!link) {
      return;
    }
    this.router.navigateByUrl(link);
    setTimeout(() => this.hideMobileSidebar.emit(), 100);
  }
}
