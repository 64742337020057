<section class="helper-page">
  <h1 class="helper-page-title">About us</h1>
<p>Welcome to <span class="helper-page-label">GamesToWait</span>, your premier source for the latest release dates and updates on upcoming video games! We are a passionate team of gamers and enthusiasts dedicated to keeping you informed and excited about the ever-evolving world of gaming.</p>

<p>At <span class="helper-page-label">GamesToWait</span>, we understand the thrill of anticipation that comes with eagerly awaiting the release of a new video game. That's why we're committed to providing you with accurate and up-to-date information on release dates, so you can mark your calendars and prepare for the next gaming adventure.</p>

<p>From highly anticipated AAA titles to indie gems, we cover a wide range of games across all platforms, ensuring that every type of gamer finds something to look forward to. Whether you're into action-packed shooters, immersive RPGs, or heart-pounding horror games, we've got you covered.</p>

<p>But we're more than just a source of release dates. We're also a vibrant community where gamers come together to discuss their favorite games, share tips and strategies, and connect with fellow enthusiasts. Our forums and social channels are buzzing with excitement, and we invite you to join the conversation.</p>

<p>So whether you're counting down the days until the next blockbuster release from companies such as
  <a class="helper-page-link" title="Steam" (click)="goToWebsite('https://store.steampowered.com/')">Steam</a>,
  <a class="helper-page-link" title="Epic Games" (click)="goToWebsite('https://store.epicgames.com/en-US/')">Epic Games</a>,
  <a class="helper-page-link" title="Nintendo" (click)="goToWebsite('https://www.nintendo.com/')">Nintendo</a>,
  <a class="helper-page-link" title="Ubisoft" (click)="goToWebsite('https://www.ubisoft.com/')">Ubisoft</a>,
  <a class="helper-page-link" title="Electronics Arts" (click)="goToWebsite('https://www.ea.com')">Electronics Arts</a> and et.c,

  <span class="helper-page-label">GamesToWait</span> is your ultimate destination for all things gaming. Join us as we embark on epic journeys, forge new friendships, and experience the magic of gaming together.</p>

<p>Welcome to <span class="helper-page-label">GamesToWait</span> — where every release date is a reason to celebrate!</p>

</section>
