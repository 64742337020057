<h2>
  <a [routerLink]="'/'" title="Home" class="logo-link">
    <span class="white-box">
      <img src="{{ env.imagePath }}logo-white.png" alt="box">
    </span>
    <span class="black-box">
      <img src="{{ env.imagePath }}logo-black.png" alt="box">
    </span>
  </a>
  <a [routerLink]="'/'" title="Home" class="logo-link-mobile">
    <img src="{{ env.imagePath }}logo-mobile.png" alt="logo">
  </a>
</h2>
