import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { ILogin, ILoggedinUser } from '@app/+shared/_models';
import { RequestService } from '@app/+shared/_services/request.service';
import { SessionService } from '@app/+shared/_services/session.service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@app/+shared/_services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  userData = new BehaviorSubject<ILoggedinUser>(null);
  userData$ = this.userData.asObservable();

  isToken = new BehaviorSubject<boolean>(false);
  isToken$ = this.isToken.asObservable();

  redirectUrl = `/admin`;

  constructor(
    private sessionService: SessionService,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: number | string,
    private router: Router
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.localStorageService.get('token')) {
        this.setToken(this.localStorageService.get('token'));
        this.getUserData();
      }
    }
  }
  /**s
   * Send authetication data to login user
   *
   * @method login
   * @param {Object} payload
   */
  login(payload: ILogin): any {
    return this.requestService.post('LOGIN', payload, null, null).subscribe(
      (response: ILoggedinUser) => {
        if (response && response.token) {
          this.setToken(response.token);
          this.setUserData(response);
        }
      },
      (error) => {
        console.log('Failed to log in');
      }
    );
  }
  /**
   * Save user token value to observable and session service
   *
   * @method setToken
   * @param {String} token
   */
  setToken(token: string): void {
    this.sessionService.token = token;
    this.isToken.next(true);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.set('token', token);
    }
    if (isPlatformServer(this.platformId)) {
      console.log('Server side');
    }
  }
  /**
   * Set user data to observable and localStorage
   *
   * @method setUserData
   * @param {Object} user
   */
  setUserData(user: ILoggedinUser): void {
    if (isPlatformBrowser(this.platformId)) {
      let data: ILoggedinUser = {
        id: '',
        email: '',
        userName: '',
        token: '',
      };
      if (user) {
        Object.keys(data).forEach((key) => {
          if (key in user) {
            data[key] = user[key];
            this.localStorageService.set(key, user[key]);
          }
        });
        this.userData.next(data);
        this.redirectToAdmin();
      }
    }
    if (isPlatformServer(this.platformId)) {
      console.log('Server side');
    }
  }
  /**
   * Redirect authenticated user to admin dashboard
   *
   * @method redirectToAdmin
   * @param none
   */
  redirectToAdmin(): void {
    this.router.navigateByUrl(this.redirectUrl);
  }
  /**
   * Return boolean value according to user's authentication status
   *
   * @remarks method converts token to bollean value
   *
   * @method isLoggedIn
   * @param none
   */
  isLoggedIn(): boolean {
    return !!this.sessionService.token;
  }

  /**
   * Return object with user data
   *
   * @method getUserData
   * @param none
   */
  getUserData(): void {
    if (isPlatformBrowser(this.platformId)) {
      const token = this.localStorageService.get('token');
      if (!token) {
        return;
      }
      const user: ILoggedinUser = {
        token: this.localStorageService.get('token'),
        id: this.localStorageService.get('id'),
        email: this.localStorageService.get('email'),
        userName: this.localStorageService.get('userName'),
      };
      this.userData.next(user);
    }
    if (isPlatformServer(this.platformId)) {
      console.log('Server side');
    }
  }
  /**
   * Remove access token from local storage and
   * redirect to root
   *
   * @method logout
   * @param none
   */
  logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isToken.next(null);
      this.localStorageService.clear();
      this.router.navigateByUrl('auth/login');
    }
    if (isPlatformServer(this.platformId)) {
      console.log('Server side');
    }
  }
}
