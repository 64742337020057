export default {
  CREATE_GAME: 'games/create-game',
  GET_ALL_GAMES: 'games',
  GET_ALL_TBA_GAMES: 'games-tba',
  GET_GAME_BY_ID: 'games/get-game?id={gameId}',
  UPDATE_GAME_BY_ID: 'games/update-game?id={gameId}',
  DELETE_GAME_BY_ID: 'games/delete-game?id={gameId}',
  // GET_GAMES_BY_ID: 'games/{gameId}',
  CREATE_GAME_CATEGORY: 'category-create', // TODO
  GET_ALL_GAME_CATEGORIES: 'category',
  SEARCH: 'search',
  LOGIN: 'auth/login',
  CONTACT: 'contact'
};
