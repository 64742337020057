import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sandwich',
  templateUrl: './sandwich.component.html',
  styleUrls: ['./sandwich.component.scss']
})
export class SandwichComponent {
  @Output() toggleSidebar: EventEmitter<void> = new EventEmitter<void>()
  /**
   * Show/Hide sidebar in (mobile viewport) on click
   *
   * @method toggleSidebar
   * @param none
   */
  handleSidebarVisibility(): void {
    this.toggleSidebar.emit();
  }
}
