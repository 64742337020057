import { Component, OnInit, OnDestroy, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private meta: Meta,
    private pageTitle: Title
  ) {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'not-found-layout');
  }
  /**
  * Add meta tags for current page
  * @method addMetaTags
  * @param none
  */
    addMetaTags(): void {
      this.pageTitle.setTitle('GamesToWait - Search');
      this.meta.addTags([
        { name: 'description', content: 'The video game you are looking for was not found' },
        { name: 'keywords', content: 'Gaming community, Gaming events, mobile gaming, release dates, gaming culture, let\'s plays, gaming tips' }
      ]);
    }
  /**
   * Cleanup just before Angular destroys the component
   *
   * @remarks Unsubscribe Observables from list and detach event handlers to avoid memory leaks
   *
   * @method ngOnDestroy
   * @param none
   */
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'not-found-layout');
  }
}
