import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '@app/+core/_services/authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let url: string = state.url;
    const isAuth = this.authService.isLoggedIn();
    if (isAuth) {
      return true;
    }
    this.authService.redirectUrl = url;
    this.router.navigate(['auth/login']);

    return false;
  }
}
