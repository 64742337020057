import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { releaseDateFormat } from '@app/+shared/_constants/date-format';
import { TO_BE_ANNOUNCED } from '@app/+shared/_utils/constants';
import moment from 'moment';

@Component({
  selector: 'app-search-autocomplete-item',
  templateUrl: './search-autocomplete-item.component.html',
  styleUrls: ['./search-autocomplete-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchAutocompleteItemComponent implements OnChanges {
  @Input() name = ''
  @Input() link = ''
  @Input() date = ''
  @Input() isBefore: boolean;
  itemName = ''
  itemLink = ''
  releaseDate = ''
  isDateBefore = false
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name && changes.name.currentValue) {
      this.itemName = changes.name.currentValue;
    }
    if (changes.link && changes.link.currentValue) {
      this.itemLink = changes.link.currentValue;
    }
    if (changes.date && changes.date.currentValue) {
      const idValidDate = moment(changes.date.currentValue, releaseDateFormat, true).isValid();
      this.releaseDate = idValidDate ? changes.date.currentValue : TO_BE_ANNOUNCED;
    }
    this.isDateBefore = changes.isBefore.currentValue;
  }
  /**
   * Go to the web page of a selected game
   *
   * @method goToWebsite
   * @param {String} link
   */
  goToWebsite(link: string):void {
    this.document.defaultView.open(link);
  }
}
