import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClickOutsideDirective } from '@app/+shared/_directives/clickOutside.directive';
import { CategoryComponent } from '@app/+shared/components/category/category.component';
import { PageSidebarComponent } from '@app/+shared/components/page-sidebar/page-sidebar.component';
import { AppShellRenderDirective } from '@app/+shared/_directives/app-shell-render.directive';
import { PaginationComponent } from '@app/+shared/components/pagination/pagination.component';
import { AppShellNoRenderDirective } from '@app/+shared/_directives/app-shell-norender.directive';
import { SearchBarComponent } from '@app/+shared/components/search-bar/search-bar.component';
import { SearchAutocompleteItemComponent } from '@app/+shared/components/search-bar/search-autocomplete-item/search-autocomplete-item.component';
import { CustomPipesModule } from '@app/+custom-pipes/custom-pipes.module';


const COMPONENTS = [
  ClickOutsideDirective,
  CategoryComponent,
  PageSidebarComponent,
  PaginationComponent,
  AppShellRenderDirective,
  AppShellNoRenderDirective,
  SearchBarComponent,
  SearchAutocompleteItemComponent
];

const CORE_MODULS = [
  CommonModule,
  FormsModule,
  CustomPipesModule
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    ...CORE_MODULS
  ],
  exports: [
    ...CORE_MODULS,
    ...COMPONENTS,
  ],
})
export class HelperModule { }
