import { Routes } from '@angular/router';
import { AboutComponent } from '@app/+core/components/helpers/about/about.component';
import { ContactComponent } from '@app/+core/components/helpers/contact/contact.component';
import { NotFoundComponent } from '@app/+core/components/helpers/not-found/not-found.component';
import { PrivacyPolicyComponent } from './+core/components/helpers/privacy-policy/privacy-policy.component';

export const routes: Routes = [
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('@app/+admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('@app/+calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('@app/+search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@app/+auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'tba',
    loadChildren: () =>
      import('@app/+tba/tba.module').then((m) => m.TbaModule),
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  { path: '**', redirectTo: '404' },
];
