<ul class="header-profile-list">
  <li>
    <h3><a [routerLink]="'admin'" title="Admin">Admin</a></h3>
  </li>
  <li>
    <h3><a [routerLink]="'/'" title="Home">Home</a></h3>
  </li>
  <li>
    <h3><span class="cp sign-out" (click)="logout()" title="Sign out">Sign out</span></h3>
  </li>
</ul>
