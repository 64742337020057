import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IPager } from '@app/+shared/_models';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() pager: IPager;
  @Output() onSetPage = new EventEmitter<number>();
  constructor() {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {}
  /**
   * Set current page for pagination
   *
   * @method setPage
   * @param {Number} page
   */
  setPage(pageNumber: number): void {
    if (pageNumber < 1) {
      return;
    }
    this.onSetPage.emit(pageNumber);
  }
}
