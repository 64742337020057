import { ChangeDetectionStrategy, Component, OnInit, Inject, Renderer2, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private meta: Meta,
    private pageTitle: Title
  ) {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
      this.renderer.addClass(this.document.body, 'privacy-policy-layout');
      this.addMetaTags();
  }
  /**
  * Add meta tags for current page
  *
  * @method addMetaTags
  * @param none
  */
  addMetaTags(): void {
    this.pageTitle.setTitle('GamesToWait - Privacy Policy');
    this.meta.addTags([
      { name: 'description', content: 'This is a privacy policy page GamesToWait' },
      { name: 'keywords', content: 'Gaming community, PC gaming, release dates, AAA titles, video games, Gaming tips' }
    ]);
  }
  /**
   * Cleanup just before Angular destroys the component
   *
   * @remarks Unsubscribe Observables from list and detach event handlers to avoid memory leaks
   *
   * @method ngOnDestroy
   * @param none
   */
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'privacy-policy-layout');
  }
}
