import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SearchService } from '@app/+core/_services/search.service';
import { IContact } from '@app/+shared/_models';
import { NotificationService } from '@app/+shared/_services/notification.service';
import { REGEX } from '@app/+shared/_utils';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactForm: UntypedFormGroup;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private notificationService: NotificationService,
    ) {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      fullName: [
        '',
        [Validators.required, Validators.minLength(2), , Validators.maxLength(30), Validators.pattern(REGEX.FULL_NAME)],
      ],
      email: [
        '',
        [Validators.required, Validators.pattern(REGEX.EMAIL_WITH_CAPITALS)],
      ],
      subject: [
        '',
        [Validators.required, Validators.minLength(2), Validators.maxLength(30)],
      ],
      message: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(500)],
      ],
    });
  }
  /**
   * Sends logged in user's credentials to server
   *
   * @remarks Redirects to Home page if user loggin was succesfull
   *
   * @method onSubmit
   * @param none
   */
    onSubmit(): void {
      /**
       * Validates form values
       *
       * @remarks Stops here if form is invalid
       */
      if (this.contactForm.invalid) {
        this.contactForm.markAllAsTouched();
        return;
      }

      const formObj = this.contactForm.value;
      const load: IContact = {
        email: formObj.email?.toLowerCase(),
        fullName: formObj.fullName,
        subject: formObj.subject,
        message: formObj.message,
      };
      this.searchService.contact(load).subscribe((response) => {
        if (response && response.status) {
          this.notificationService.showSuccess(
            'Message was sent successfully'
          );

          setTimeout(() => this.contactForm.reset(), 1000);
        }
      }, (error) => {
        this.notificationService.showError('Could not send message');
      })



    }
}
