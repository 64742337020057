import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { searchResultsUrl } from '@app/+admin/_constants/urls';
import { IContact, ISearchResult } from '@app/+shared/_models';
import { RequestService } from '@app/+shared/_services/request.service';
import { CATEGORY_ID_KEY } from '@app/+shared/_utils/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  redirectUrl = searchResultsUrl;
  searchSubject = new BehaviorSubject<string>('');
  searchSubject$ = this.searchSubject.asObservable();
  searchResponse = new BehaviorSubject<ISearchResult[]>(null);
  searchResponse$ = this.searchResponse.asObservable();

  searchableData = new BehaviorSubject<ISearchResult[]>(null);
  searchableData$ = this.searchableData.asObservable();

  hideCategoryDropdown = new BehaviorSubject<boolean>(false);
  hideCategoryDropdown$ = this.hideCategoryDropdown.asObservable();

  categoryIdData = new BehaviorSubject<number[]>(null);
  categoryIdData$ = this.categoryIdData.asObservable();

  searchObj = {
    text: '',
    categoryIds: [],
  }
  constructor(
    private requestService: RequestService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: number | string
    ) {}
  /**
   * Get searchable data from components and submit the to server.
   *
   * @remarks this method gets data from after keyup event
   *
   * @method searchDataOnKeyup
   * @param {String} searchText
   */
  searchDataOnKeyup(searchText: string): any {
    if (searchText) {
      this.searchObj.text = searchText;
      this.searchSubject.next(searchText);
      return this.requestService.post('SEARCH', this.searchObj).subscribe(
        (response: ISearchResult[]) => {
          if (response) {
            this.searchResponse.next(response);
          }
        },
        (error) => {
          console.log('Could not perform search on keyup ', error);
        }
      );
    }
  }
  /**
   * Get searchable data from components and submit the to server.
   *
   * @remarks this method gets data on form submit event
   *
   * @method searchDataOnEnter
   * @param {String} searchText
   */
  searchDataOnEnter(searchText: string): any {
    if (searchText) {
      this.searchObj.text = searchText;
      return this.requestService.post('SEARCH', this.searchObj).subscribe(
        (response: ISearchResult[]) => {
          if (response) {
            this.searchableData.next(response);
            const params = {queryParams: {text: this.searchObj.text, category: this.searchObj.categoryIds}}
            this.router.navigate([this.redirectUrl], params);
          }
        },
        (error) => {
          console.log('Could not perform search on submit ', error);
        }
      );
    }
  }
  /**
   * Get searchable data based on selected category ids.
   *
   * @method searchByCategory
   * @param {Object} route
   * @param {Array} categoryIdList
   */
  searchByCategory(route: any, categoryIdList: number[] | null): any {

    this.searchObj.categoryIds = categoryIdList && categoryIdList.length > 0 ? categoryIdList : null;

    return this.requestService.post('SEARCH', this.searchObj).subscribe(
      (response: ISearchResult[]) => {
        if (response) {
          this.searchableData.next(response);
          this.hideCategoryDropdown.next(true);
          const params = {text: this.searchObj.text, [CATEGORY_ID_KEY]: this.searchObj.categoryIds};
          this.router.navigate(
            [this.redirectUrl],
            {
              relativeTo: route,
              queryParams: params,
              queryParamsHandling: 'merge',
              // preserve the existing query params in the route
            }

          );
        }
      },
      (error) => {
        console.log('Could not perform search.. ', error);
      }
    );
  }
  /**
   * Get selected category numbers within array
   *
   * @method setCategoryIds
   * @param {Array} data
   */
  setCategoryIds(data: number[]): void {
    this.categoryIdData.next(data);
  }
  /**
   * Send contact form data
   *
   * @method contact
   * @param {Object} payload
   */
  contact(payload: IContact): any {
    return this.requestService.post('CONTACT', payload, null, null);
  }
  /**
   * Go to a external website
   *
   * @method goToWebsite
   * @param {String} url
   */
  goToWebsite(url: string): void {
    if (!url) {
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }
}
