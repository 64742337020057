import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LocalStorageService } from './local-storage.service';
import { AuthenticationService } from '@app/+core/_services/authentication.service';

@Injectable()
export class SessionService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: number | string,
    private localStorageService: LocalStorageService
  ) {}

  set token(token: string) {
    if (isPlatformBrowser(this.platformId)) {
      if (token) {
        this.localStorageService.set('token', token);
      } else {
        this.localStorageService.delete('token');
      }
    }
  }
  get token(): string {
    let localToken = '';
    if (isPlatformBrowser(this.platformId)) {
      localToken = this.localStorageService.get('token');
    }
    if (isPlatformServer(this.platformId)) {
      console.log('Server side');
    }
    return localToken;
  }
  /**
   * Clear token and localStorage data after logout
   *
   * @method logout
   * @param none
   */
  logout(): void {
    this.token = null;

    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.clear();
    }
    if (isPlatformServer(this.platformId)) {
      console.log('Server side');
    }
    if (isPlatformServer(this.platformId)) {
      window.location.href = '/';
    }
  }
}
