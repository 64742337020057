import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  currentThemeSubject = new BehaviorSubject<string>('');
  currentThemeSubject$ = this.currentThemeSubject.asObservable();
  constructor(private toastr: ToastrService) {}
  /**
   * Show success notification alert
   *
   * @method showSuccess
   * @param {String} message
   * @param {String} note
   */
  showSuccess(message: string = 'Success ', note: string = ''): void {
    this.toastr.success(message, note);
  }
  /**
   * Show informative notification alert
   *
   * @method showInfo
   * @param {String} message
   * @param {String} note
   */
  showInfo(message: string = 'Success ', note: string = ''): void {
    this.toastr.info(message, note);
  }
  /**
   * Show error/failure notification alert
   *
   * @method showError
   * @param {String} message
   * @param {String} note
   */
  showError(message: string = 'Error ', note: string = 'Try again'): void {
    this.toastr.error(message, note, {
      timeOut: 3000,
    });
  }
  /**
   * Set current theme based on user selection
   *
   * @method setCurrentTheme
   * @param {String} themeName
   */
  setCurrentTheme(themeName: string): void {
    this.currentThemeSubject.next(themeName);
  }
}
