import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError } from 'rxjs/operators'
import { throwError } from 'rxjs'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = 'Unknown error!'
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMsg = `Error: ${error.error.message}`
          console.log('Client-side error ---- ' + errorMsg)
        } else {
          // Server-side errors
          errorMsg = `Error Code: ${error.status}\nMessage: ${error.message}`
          console.log('Server-side error ---- ' + errorMsg)
        }
        return throwError(error)
      }),
    )
  }
}
