import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NotificationService } from '@app/+shared/_services/notification.service';

@Component({
  selector: 'app-switch-theme',
  templateUrl: './switch-theme.component.html',
  styleUrls: ['./switch-theme.component.scss']
})
export class SwitchThemeComponent implements OnInit {
  isChechboxChecked = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private notificationService: NotificationService
  ) {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    const themeName = localStorage.getItem('theme');
    if (themeName === 'theme-dark') {
      this.isChechboxChecked = false;
      this.notificationService.setCurrentTheme(themeName)
    } else if (themeName === 'theme-light') {
      this.isChechboxChecked = true;
      this.notificationService.setCurrentTheme(themeName)
    }
  }
  /**
   * Change main theme based on user choice
   *
   * @method onCheckboxChange
   * @param {Event} event
   */
  onCheckboxChange(event: Event): void {
    const ischecked = (<HTMLInputElement>event.target).checked;
    const themeName = ischecked ? 'theme-light' : 'theme-dark'

    if (!ischecked) {
      localStorage.setItem('theme', themeName);
      this.renderer.addClass(this.document.body, 'theme-dark');
      this.renderer.removeClass(this.document.body, 'theme-light');
      this.notificationService.setCurrentTheme(themeName)
    } else {
      localStorage.setItem('theme', themeName);
      this.renderer.addClass(this.document.body, 'theme-light');
      this.renderer.removeClass(this.document.body, 'theme-dark');
      this.notificationService.setCurrentTheme(themeName)
    }
  }
}
