<section class="helper-page">

  <h1 class="helper-page-title">Privacy Policy</h1>

  <p>At <span class="helper-page-label">GamesToWait</span>, we understand the importance of privacy and are committed to protecting the personal information of our users. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and interact with our services.</p>

  <h1 class="helper-page-subtitle">Information We Collect:</h1>

  <p>Personal Information: We may collect personal information, such as your name and email address, when you voluntarily provide it to us for purposes such as newsletter subscriptions or account registration.</p>
  <p>Usage Information: We automatically collect certain information about your device, including your IP address, browser type, operating system, and pages visited, to analyze trends and improve our website's functionality.</p>
  <p>Cookies: We use cookies and similar tracking technologies to enhance your browsing experience and provide personalized content. You have the option to disable cookies in your browser settings, but please note that some features of our website may not function properly as a result.</p>

  <h1 class="helper-page-subtitle">How We Use Your Information:</h1>

  <p>To Provide Services: We use your information to deliver the services and features you request, such as notifying you about upcoming video game release dates and events.</p>
  <p>To Communicate: We may send you promotional emails and newsletters about our products, services, and partnerships, but you have the option to opt out of receiving these communications at any time.</p>
  <p>To Improve: We analyze usage data to understand how visitors interact with our website and make enhancements to optimize user experience.</p>

  <h1 class="helper-page-subtitle">Information Sharing:</h1>

  <p>Third-Party Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website, conducting business, or servicing you, but only to the extent necessary to fulfill these purposes.</p>
  <p>Affiliate Partners: We may share aggregated or anonymized data with our affiliate partners for analytical or marketing purposes, but we do not disclose your personal information without your consent.</p>
  <p>Legal Compliance: We may disclose your information if required to do so by law or in response to valid legal requests, such as subpoenas or court orders.</p>

  <h1 class="helper-page-subtitle">Data Security:</h1>

  <p>We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
  <p>However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

  <h1 class="helper-page-subtitle">Children's Privacy:</h1>

  <p>Our website is not intended for children under the age of 13, and we do not knowingly collect personal information from individuals under this age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately so that we can take appropriate action.</p>

  <h1 class="helper-page-subtitle">Changes to This Policy:</h1>

  <p>We reserve the right to update or modify this Privacy Policy at any time, and any changes will be posted on this page with the revised effective date. By continuing to use our website after such changes, you acknowledge and agree to the updated policy.</p>

  <h1 class="helper-page-subtitle">Contact Us:</h1>

  <p>If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please <a [routerLink]="'contact'">contact us</a>.
  By using <span class="helper-page-label">GamesToWait</span>, you consent to the terms outlined in this Privacy Policy.</p>

  <p>This Privacy Policy was last updated on 4th of March, 2024.</p>

</section>
