import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdminService } from '@app/+admin/_services/admin.service';
import { AuthenticationService } from '@app/+core/_services/authentication.service';
import { SearchService } from '@app/+core/_services/search.service';
import { ICategory, ILoggedinUser } from '@app/+shared/_models';
import { IEnvironment } from '@app/+shared/_models/environment';
import { CATEGORY_ID_KEY } from '@app/+shared/_utils/constants';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, AfterViewInit {
  env: IEnvironment = environment;
  categoriesList: ICategory[] = [];
  profile;
  modules = {
    auth: '/auth/',
    search: '/search',
    profile: '/profile/',
    provider: '/provider/',
    doctor: '/doctor/'
  };
  isProfileMenuOpen = false;
  isModalOpen = false;
  isSidebarOpen = false;
  showCategoryDropdown = false;
  fixedLayout;
  themeType = 'theme-dark';
  selectedCategoriesAmount = 0;
  isLoggedIn$: Observable<boolean>;
  isLoggedOut$: Observable<boolean>;
  profile$: Observable<ILoggedinUser>;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public cd: ChangeDetectorRef,
    private adminService: AdminService,
    private searchService: SearchService,
  ) {
    this.themeType = localStorage.getItem('theme') === 'theme-dark' ? 'theme-dark' : 'theme-light'
  }
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    this.defineCurrentRoute();
    this.getCategories();
    this.getSelectedCategoriesAmount();
  }
  /**
   * A lifecycle hook that is called after Angular has fully initialized
   * a component's view.
   *
   * @method ngAfterViewInit
   * @param none
   */
  ngAfterViewInit(): void {
    this.isLoggedIn$ = this.authService.isToken$.pipe(
      tap((data: boolean) => {
        if (data) {
          this.profile$ = this.authService.userData$;
        }
      })
    );
    this.cd.detectChanges();
  }

  /**
   * Listen for current route changes and change header's UI accordingly
   *
   * @method defineCurrentRoute
   * @param none
   */
  defineCurrentRoute(): void {
    // listen to events from Router
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url
        const url = event.urlAfterRedirects;
        if (
          url === this.modules.auth + '/' ||
          url === this.modules.auth + 'calendar'
        ) {
          this.fixedLayout = true;
        } else {
          this.fixedLayout = false;
        }
      }
    });
  }
  /**
   * Get list of categories
   *
   * @method getCategories
   * @param none
   */
  getCategories(): void {
    this.adminService.getAllCategories().subscribe(
      (data: ICategory[]) => {
        if (data && data.length > 0) {
          this.categoriesList = data.sort((a, b) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0));
        }
        this.cd.detectChanges();
      },
      error => {
        console.log('Could not get category data ');
      }
    );

  }
  /**
   * Get list of selected categories
   *
   * @remark this function gets only categories selected by user
   *
   * @method getSelectedCategoriesAmount
   * @param none
   */
  getSelectedCategoriesAmount(): void {
    const selectedCategories = localStorage.getItem(CATEGORY_ID_KEY);
    const categoriesIDs = eval(JSON.parse(selectedCategories));

    this.selectedCategoriesAmount = categoriesIDs?.length

    this.searchService.categoryIdData$.subscribe((categoriesIDs: number[]) => {
      if (categoriesIDs?.length > 0) {
        this.selectedCategoriesAmount = categoriesIDs?.length
        this.cd.detectChanges();
      } else if(categoriesIDs?.length === 0) {
        this.selectedCategoriesAmount = 0;
      }
    })
    this.searchService.hideCategoryDropdown.subscribe((value: boolean) => {
      if (value) {
        this.showCategoryDropdown = false;
        this.cd.detectChanges();
      }
    })
  }
  /**
   * Show/hide profile dropdown on click
   *
   * @method toggleProfileDropdoown
   * @param {MouseEvent} event
   */
  toggleProfileDropdoown(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    }
  }
  /**
   * Hide dropdown if click was outside of it
   *
   * @method hideProfileDropdoown
   * @param {Object} event
   */
  hideProfileDropdoown(event: object): void {
    if (!event) {
      return;
    }
    this.isProfileMenuOpen = false;
  }
  /**
   * Hide dropdown if click was outside of it
   *
   * @method hideCategoryDropdown
   * @param {Boolean} event
   */
  hideCategoryDropdown(event: boolean): void {
    if (!event) {
      return;
    }
    this.showCategoryDropdown = false;
  }
  /**
   * Logout user by removin..g token value from localStorage
   *
   * @method logout
   * @param {Boolean} logoutUser
   */
  logout(logoutUser: boolean): void {
    if (logoutUser) {
      this.authService.logout();
    }
  }
  /**
   * Show/Hide sidebar in (mobile viewport) on click
   *
   * @method toggleSidebar
   * @param none
   */
  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
  /**
   * Hide sidebar in (mobile viewport) on click
   *
   * @method hideSidebar
   * @param none
   */
  hideSidebar(): void {
    this.isSidebarOpen = false;
  }
}
