<header>
  <div class="header-left">
    <app-header-logo></app-header-logo>
  </div>
  <!--.header-left-->
  <div class="header-right">
    <ul class="navigation-menu">
      <li>
        <a
          class="navigation-menu-item category-menu-item"
          (click)="showCategoryDropdown = true"
          title="Select genres">
          Genres
          <span class="selected-categories-amount" *ngIf="selectedCategoriesAmount > 0">
            {{ selectedCategoriesAmount }}
          </span>
        </a>
      </li>
      <li class="tba-link">
        <a class="navigation-menu-item"  title="Date To Be Annouced" [routerLink]="'/tba/games'">Games TBA</a>
      </li>
      <li>
        <app-switch-theme></app-switch-theme>
      </li>
    </ul>

    <ng-container *ngIf="isLoggedIn$ | async">
      <div class="profile-wrapper ml-65" (clickOutside)="hideProfileDropdoown($event)" clickOutside>
        <h3 class="profile-username" (click)="toggleProfileDropdoown($event)"
          *ngIf="profile$ | async as profile">
          {{ profile?.userName }}
        </h3>
        <div class="profile-dropdown" [ngClass]="{'show' : isProfileMenuOpen }">
          <app-header-profile (logoutUser)="logout($event)">
          </app-header-profile>
        </div>
      </div>
    </ng-container>

    <app-sandwich (toggleSidebar)="toggleSidebar()"></app-sandwich>

  </div>
</header>


<div class="gtw-modal-backdrop" *ngIf="isSidebarOpen" (click)="hideSidebar()"></div>

<div class="mobile-sidebar opened" [ngClass]="{'opened' : isSidebarOpen}">
  <div class="close-modal" id="hide-sidebar" (click)="hideSidebar()">
    <img src="{{ env.imagePath }}close-light.svg" *ngIf="themeType === 'theme-light'" alt="close sidebar">
    <img src="{{ env.imagePath }}close-dark.svg" *ngIf="themeType === 'theme-dark'" alt="close sidebar">
  </div>

  <app-mobile-sidebar-nav (hideMobileSidebar)="hideSidebar()"></app-mobile-sidebar-nav>

</div>


<app-category-filter
  *ngIf="showCategoryDropdown"
  [categoriesList]="categoriesList"
  (hideCategoryDropdown)="hideCategoryDropdown($event)"
  >
</app-category-filter>

