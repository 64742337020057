import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error-interceptor';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/+core/core.module';
import { GuestGuard } from '@app/+shared/_guards/guest.guard';
import { AuthGuard } from '@app/+shared/_guards/auth.guard';
import { SharedModule } from '@app/+shared/shared.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { RequestService } from '@app/+shared/_services/request.service';
import { SessionService } from '@app/+shared/_services/session.service';
import { NotificationService } from '@app/+shared/_services/notification.service';
import { ToastrModule } from 'ngx-toastr';
import { PrivacyPolicyComponent } from './+core/components/helpers/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [AppComponent, PrivacyPolicyComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    SharedModule,
    CommonModule,
    AppRoutingModule,
  ],
  providers: [
    RequestService,
    SessionService,
    NotificationService,
    GuestGuard,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
