import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    if (localStorage.getItem('theme') === 'theme-dark') {
      this.setTheme('theme-dark');
    } else {
      this.setTheme('theme-light');
    }
  }
  /**
   * Get theme data saved by the user
   *
   * @method setTheme
   * @param {String} themeName
   */
  setTheme(themeName: string): void {
    localStorage.setItem('theme', themeName);
    this.renderer.addClass(this.document.body, themeName);
  }
}
