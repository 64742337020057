// import moment from 'moment';
import moment from 'moment';
import { dateAndTimeDashed } from '@app/+shared/_constants/date-format';

export class CurrentDate {
  // Get current date and format it according to 'MM-DD-YYYY HH:mm:ss A'
  getCurrentDate(): string {
    return moment().format(dateAndTimeDashed);
  }
}
