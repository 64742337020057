<ul class="pagination">
  <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
    <a class="page-link" id="first-page" (click)="setPage(1)">First</a>
  </li>
  <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
    <a class="page-link" (click)="setPage(pager.currentPage - 1)">Previous</a>
  </li>
  <li class="page-item" *ngFor="let page of pager.pages"
    [ngClass]="{active:pager.currentPage === page}">
    <a class="page-link" (click)="setPage(page)">{{page}}</a>
  </li>
  <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
    <a class="page-link" (click)="setPage(pager.currentPage + 1)">Next</a>
  </li>
  <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
    <a class="page-link" id="last-page" (click)="setPage(pager.totalPages)">Last</a>
  </li>
</ul>
