import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '@app/+core/components/header/header.component';
import { FooterComponent } from '@app/+core/components/footer/footer.component';
import { AboutComponent } from '@app/+core/components/helpers/about/about.component';
import { ContactComponent } from '@app/+core/components/helpers/contact/contact.component';
import { NotFoundComponent } from '@app/+core/components/helpers/not-found/not-found.component';
import { HeaderProfileComponent } from './components/header/header-profile/header-profile.component';
import { HeaderLogoComponent } from './components/header/header-logo/header-logo.component';
import { SwitchThemeComponent } from './components/header/switch-theme/switch-theme.component';
import { HelperModule } from '@app/+helper/helper.module';
import { CategoryFilterComponent } from './components/header/category-filter/category-filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MobileSidebarNavComponent } from './components/header/mobile-sidebar-nav/mobile-sidebar-nav.component';
import { SandwichComponent } from './components/header/sandwich/sandwich.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    NotFoundComponent,
    HeaderProfileComponent,
    HeaderLogoComponent,
    SwitchThemeComponent,
    CategoryFilterComponent,
    MobileSidebarNavComponent,
    SandwichComponent,
  ],
  imports: [CommonModule, RouterModule, HelperModule, ReactiveFormsModule],
  exports: [
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    NotFoundComponent
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only. '
      );
    }
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
