<div class="filter-dropdown">
  <div class="filter-dropdown__body">

    <div class="filter-dropdown__wrapper">

      <ng-container *ngIf="checkboxesDataList">
        <ul class="filter-dropdown__list">
          <li *ngFor="let item of checkboxesDataList; let i = index">
            <label class="checkbox-container inline is-black" for="category-{{item.id}}">
              <span class="checkbox-text">{{ item.name }}</span>
              <input type="checkbox" id="category-{{item.id}}"
                [name]="item.categoryLabel"
                class="category-item" value="{{ item.id }}" [(ngModel)]="item.isChecked" (change)="changeSelection()" />
              <span class="checkmark"></span>
            </label>
          </li>
        </ul>
      </ng-container>
    </div>

  </div>
  <!---.filter-dropdown__body-->
  <div class="filter-dropdown__footer">
    <span class="btn btn-md btn-warning pull-right gtw-form__button" id="reset-categories"
      (click)="resetAll()">
      Clear
    </span>
    <span class="btn btn-md btn-primary pull-right gtw-form__button" id="add-category"
      (click)="addCategory()">
      Apply
    </span>
  </div>
</div>
