<div class="gtw-search" (clickOutside)="hideAutocompleteDropDown($event)" clickOutside>
  <form (keydown)="searchOnEnter($event)" id="search-bar-form">
    <label class="invisible-label" for="search-input">Search </label>
    <input type="search" id="search-input" #searchText (input)="searchGameByText(searchText.value)"
      placeholder="Search" autocomplete="off">

    <button
       [ngClass]="{'search-icon' : isSearchIcon, 'reset-icon' : !isSearchIcon}"
       (click)="!isSearchIcon ? resetSearch() : null;"
       >
      <img *ngIf="isSearchIcon" src="{{ env.imagePath }}search-ic.svg" alt="search" />
      <img *ngIf="!isSearchIcon" src="{{ env.imagePath }}ic-reset.svg" alt="reset" />
    </button>
  </form>

  <div class="autocomplete-list-box" *ngIf="showAutocomplete">
    <ul class="autocomplete-list">
      <ng-container *ngIf="searchResponseList && searchResponseList.length > 0">
        <li *ngFor="let item of searchResponseList | slice:0:10;">
          <app-search-autocomplete-item
            [name]="item.name"
            [link]="item.link"
            [date]="item.releaseDate"
            [isBefore]="item.isBefore">
          </app-search-autocomplete-item>
        </li>
      </ng-container>
      <ng-container *ngIf="searchResponseList && searchResponseList.length === 0">
        <li>
          <div class="search-noresult-item">
            <span class="autocomplete-list-not-found">No results...</span>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="!searchResponseList">
        <li>
          <div class="search-noresult-item">
            <span class="autocomplete-list-not-found"> Loading.. </span>
          </div>
        </li>
      </ng-container>
    </ul>

    <div class="search-show-more" *ngIf="searchResponseList && searchResponseList.length >= 3">
      <button class="search-show-more-btn" (click)="showMoreByKeyowrd(searchText.value)">Show more</button>
    </div>
  </div>

</div>
