import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import endPoints from '@app/+shared/_configs/endpoints';
import { environment } from '@env';
import { SessionService } from '@app/+shared/_services/session.service';

interface UrlParams {
  key: string;
  value: string | number;
}

@Injectable()
export class RequestService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  // TODO add description and return type
  post(
    urlAlias,
    body,
    urlParams?: UrlParams,
    queryParams?: UrlParams[],
    isAuthorized = true
  ) {
    let url = endPoints[urlAlias];
    let headers: HttpHeaders = new HttpHeaders();

    if (isAuthorized) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.sessionService.token}`
      );
    }

    if (urlParams) {
      url = url.replace(`{${urlParams.key}}`, urlParams.value);
    }
    if (queryParams) {
      queryParams.forEach((param, index) => {
        const sign = index ? '&' : '?';
        url += `${sign}${param.key}=${param.value}`;
      });
    }

    return this.http
      .post(`${environment.apiPath}${url}`, body, { headers })
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            this.sessionService.logout();
          }
          return throwError(error);
        })
      );
  }
  // TODO add description and return type
  get(
    urlAlias,
    urlParams?: UrlParams,
    queryParams?: UrlParams[],
    isAuthorized = true
  ) {
    let url = endPoints[urlAlias];
    let headers: HttpHeaders = new HttpHeaders();

    if (isAuthorized) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.sessionService.token}`
      );
    }
    if (urlParams) {
      url = url.replace(`{${urlParams.key}}`, urlParams.value);
    }

    if (queryParams) {
      queryParams.forEach((param, index) => {
        const sign = index ? '&' : '?';
        url += `${sign}${param.key}=${param.value}`;
      });
    }
    return this.http.get(`${environment.apiPath}${url}`, { headers }).pipe(
      catchError(error => {
        if (error.status === 401) {
          // this.sessionService.logout();
        }
        return throwError(error);
      })
    );
  }
  // TODO add description and return type
  delete(
    urlAlias,
    urlParams?: UrlParams,
    queryParams?: UrlParams[],
    isAuthorized = true
  ) {
    let url = endPoints[urlAlias];
    let headers: HttpHeaders = new HttpHeaders();

    if (isAuthorized) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.sessionService.token}`
      );
    }

    if (urlParams) {
      url = url.replace(`{${urlParams.key}}`, urlParams.value);
    }

    if (queryParams) {
      queryParams.forEach((param, index) => {
        const sign = index ? '&' : '?';
        url += `${sign}${param.key}=${param.value}`;
      });
    }
    return this.http.delete(`${environment.apiPath}${url}`, { headers }).pipe(
      catchError(error => {
        if (error.status === 401) {
          // this.sessionService.logout();
        }
        return throwError(error);
      })
    );
  }
  // TODO add description and return type
  put(
    urlAlias,
    body,
    urlParams?: UrlParams,
    queryParams?: UrlParams[],
    isAuthorized = true
  ) {
    let url = endPoints[urlAlias];
    let headers: HttpHeaders = new HttpHeaders();

    if (isAuthorized) {
      headers = headers.set(
        'Authorization',
        `Bearer ${this.sessionService.token}`
      );
    }
    if (urlParams) {
      url = url.replace(`{${urlParams.key}}`, urlParams.value);
    }

    if (queryParams) {
      queryParams.forEach((param, index) => {
        const sign = index ? '&' : '?';
        url += `${sign}${param.key}=${param.value}`;
      });
    }

    return this.http
      .put(`${environment.apiPath}${url}`, body, { headers })
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            console.log('Error 401: ');
            // this.sessionService.logout();
          }
          return throwError(error);
        })
      );
  }
}
