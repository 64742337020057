import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SearchService } from '@app/+core/_services/search.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutComponent implements OnInit {
  constructor(
    private meta: Meta,
    private pageTitle: Title,
    private searchService: SearchService
  ) {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {
    this.addMetaTags();
  }
  /**
  * Add meta tags for current page
  * @method addMetaTags
  * @param none
  */
  addMetaTags(): void {
    this.pageTitle.setTitle('GamesToWait - About');
    this.meta.addTags([
      { name: 'description', content: 'Get the latest release dates and updates on upcoming video games' },
      { name: 'keywords', content: 'gaming tips, console gaming, single-player games, multiplayer gaming, gaming news, release dates' }
    ]);
  }
  /**
   * Redirect to a game's website
   *
   * @method goToWebsite
   * @param {String} url
   */
  goToWebsite(url: string): void {
    this.searchService.goToWebsite(url);
  }
}
