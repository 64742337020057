import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
})
export class HeaderProfileComponent implements OnInit {
  @Output() logoutUser = new EventEmitter<boolean>();
  constructor() {}
  /**
   * Initialize the directive/component after Angular first displays the data-bound properties
   * and sets the directive/component's input properties.
   *
   * @method ngOnInit
   * @param none
   */
  ngOnInit(): void {}
  /**
   * Logout user by removing token value from localStorage
   *
   * @method logout
   * @param none
   */
  logout(): void {
    this.logoutUser.emit(true);
  }
}
